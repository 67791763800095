import React from 'react';

import {
    container,
    heading,
    categories,
    category,
    inactiveCategory,
} from './news-categories.module.scss';
import { INewsCategory } from '../../models/news-category.model';
import useTranslations from '../../hooks/use-translations';
import { TButtonProps } from '../atoms/button';

import Button from '../atoms/button';
import AccessibleHeading from '../atoms/accessible-heading';
import AccessibleContent from '../atoms/accessible-content';
import Content from '../atoms/content';

interface IButtonProps {
    as: 'link';
    kind: TButtonProps['kind'];
    theme: TButtonProps['theme'];
}

interface INewsCategoriesProps {
    className?: string;
    newsCategories: INewsCategory[];
    activeNewsCategoryId: number;
}

const NewsCategories: React.FC<INewsCategoriesProps> = ({
    newsCategories,
    activeNewsCategoryId,
}) => {
    const t = useTranslations('NewsCategories');
    const buttonProps: IButtonProps = {
        as: 'link',
        kind: 'text',
        theme: 'dark',
    };
    const isCatActive = !!activeNewsCategoryId;

    return (
        <div className={container}>
            <AccessibleHeading
                screenReadersContent={t.accessibleHeading}
                numberOfStyle={8}
                isRegular={true}
                className={heading}
                Tag={'p'}
            >
                {t.heading}
            </AccessibleHeading>
            <div className={categories}>
                <Button to={`/news`} {...buttonProps}>
                    <Content
                        numberOfStyle={1}
                        Tag={'span'}
                        isRegular={isCatActive}
                        className={`${category} ${isCatActive ? inactiveCategory : ''}`}
                    >
                        {t.all}
                    </Content>
                </Button>
                {newsCategories.map((cat) => {
                    const isCatActive = activeNewsCategoryId !== cat.categoryId;

                    return (
                        <Button
                            key={cat.categoryId}
                            to={cat.pathname}
                            className={`${category} ${isCatActive ? inactiveCategory : ''}`}
                            {...buttonProps}
                        >
                            <AccessibleContent
                                screenReadersContent={t.category({ name: cat.title })}
                                numberOfStyle={1}
                                Tag={'span'}
                                isRegular={isCatActive}
                            >
                                {cat.title}
                            </AccessibleContent>
                        </Button>
                    );
                })}
            </div>
        </div>
    );
};

export default NewsCategories;
