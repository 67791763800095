import React from 'react';
import { graphql } from 'gatsby';
import { IPageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { grid, container } from './news.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { INewsCategory } from '../models/news-category.model';
import { INews } from '../models/news.model';
import { getNodes } from '../utils/get-nodes';
import useTranslations from '../hooks/use-translations';

import MainLayout from '../layouts/main-layout';
import Heading from '../components/atoms/heading';
import NewsCategories from '../components/molecules/news-categories';
import NewsListing from '../components/molecules/news-listing';
import Pagination from '../components/molecules/pagination';

interface INewsQueryResults {
    allNews: IQueryAllResult<INews>;
    allNewsCategory: IQueryAllResult<INewsCategory>;
}

interface INewsContext extends IPageContext {
    categoryId: number;
}

interface INewsProps {
    readonly pageContext: INewsContext;
    data: INewsQueryResults;
}

const News: React.FC<INewsProps> = ({ data, pageContext }) => {
    const t = useTranslations('News');
    const news = getNodes(data.allNews);
    const newsCategories = getNodes(data.allNewsCategory);
    const { paginationPaths, pageNumber, pageCount, categoryId } = pageContext;

    return (
        <MainLayout className={grid}>
            <div className={container}>
                <Heading numberOfStyle={3}>{t.heading}</Heading>
                <NewsCategories newsCategories={newsCategories} activeNewsCategoryId={categoryId} />
                <NewsListing news={news} />
                <Pagination
                    as={'listing'}
                    paginationPaths={paginationPaths}
                    pageNumber={pageNumber}
                    pageCount={pageCount}
                />
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query ($newsIds: [Int]) {
        allNews(filter: { newsId: { in: $newsIds } }, sort: { order: DESC, fields: publishedAt }) {
            edges {
                node {
                    ...newsCardFields
                }
            }
        }

        allNewsCategory(sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...newsCategoryFragment
                }
            }
        }
    }
`;

export default News;
